import {
  Datagrid,
  DateField,
  FunctionField,
  type RaRecord,
  TextField,
} from "react-admin";
import { type Props2String, dateTimeOptionsWithoutSecondsDeCH } from "./Common";

export const MissionInfoStatusResource = "missionstatus";

export const MissionInfoStatusDatagrid = () => (
  <Datagrid bulkActionButtons={false} rowStyle={() => ({ height: "2.25rem" })}>
    <FunctionField
      label="Ereignis"
      render={(record: any) => {
        if (!record || !record.statusCode) return "–";
        return <p>{getCorrectStatus(record.statusCode)}</p>;
      }}
    />
    <FunctionField
      label="Ort"
      render={(record: any) => {
        const url = `https://map.geo.admin.ch/#/map?&swisssearch=+${record?.longitude}++${record?.latitude}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {record.latitude} {record.longitude}
          </a>
        );
      }}
    />
    <TextField source={MissionInfoStatusProps.altitude} />
    <DateField
      source={MissionInfoStatusProps.statusTimestamp}
      showTime
      locales="de-CH"
      options={dateTimeOptionsWithoutSecondsDeCH}
    />
    <TextField source={MissionInfoStatusProps.source} />
  </Datagrid>
);

enum Status {
  ALARMED_AL = "ALARMED_AL",
  ACKNOWLEDGED_AK = "ACKNOWLEDGED_AK",
  TAKE_OFF_BASE_03 = "TAKE_OFF_BASE_03",
  ON_SITE_04 = "ON_SITE_04",
  LOCAL_OPS_10 = "LOCAL_OPS_10",
  TAKE_OFF_SITE_07 = "TAKE_OFF_SITE_07",
  DESTINATION_08 = "DESTINATION_08",
  TAKE_OFF_DESTINATION_09 = "TAKE_OFF_DESTINATION_09",
  UNIT_READY_01 = "UNIT_READY_01",
  UNIT_READY_BASE_02 = "UNIT_READY_BASE_02",
  NOT_READY_X1 = "NOT_READY_X1",
  NOT_READY_BASE_X2 = "NOT_READY_BASE_X2",
  NOT_READY_DESTINATION_X8 = "NOT_READY_DESTINATION_X8",
  NOT_READY_SITE_RETURN_X9 = "NOT_READY_SITE_RETURN_X9",
  OUT_OF_SERVICE_06 = "OUT_OF_SERVICE_06",
  WAYPOINT = "WAYPOINT",
  SPECIALIST = "SPECIALIST",
}

const getCorrectStatus = (status: string) => {
  switch (status) {
    case Status.ALARMED_AL:
      return "Alarmiert";
    case Status.ACKNOWLEDGED_AK:
      return "Akzeptiert";
    case Status.TAKE_OFF_BASE_03:
      return "Zum Einsatzort";
    case Status.ON_SITE_04:
      return "Am Einsatzort";
    case Status.LOCAL_OPS_10:
      return "Local Operations";
    case Status.TAKE_OFF_SITE_07:
      return "Zum Zielort";
    case Status.DESTINATION_08:
      return "Am Zielort";
    case Status.TAKE_OFF_DESTINATION_09:
      return "Zurück zur Basis";
    case Status.UNIT_READY_01:
      return "Einsatzbereit: Ausserhalb Basis";
    case Status.UNIT_READY_BASE_02:
      return "Einsatzbereit: Auf Basis";
    case Status.NOT_READY_X1:
      return "Nicht einsatzbereit: Ausserhalb Basis";
    case Status.NOT_READY_BASE_X2:
      return "Nicht einsatzbereit: Auf Basis";
    case Status.NOT_READY_DESTINATION_X8:
      return "Nicht einsatzbereit: Am Zielort";
    case Status.NOT_READY_SITE_RETURN_X9:
      return "Nicht einsatzbereit: Zurück zur Basis";
    case Status.OUT_OF_SERVICE_06:
      return "Ausser Dienst";
    case Status.WAYPOINT:
      return "Wegpunkt";
    case Status.SPECIALIST:
      return "Spezialist";
  }
};
export type MissionInfoStatus = RaRecord & {
  id: number;
  missionId: number;
  statusCode: string;
  statusTimestamp: number;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  source: string;
};

export const MissionInfoStatusProps: Props2String<MissionInfoStatus> = {
  id: "id",
  missionId: "missionId",
  statusCode: "statusCode",
  statusTimestamp: "statusTimestamp",
  latitude: "latitude",
  longitude: "longitude",
  altitude: "altitude",
  source: "source",
};
