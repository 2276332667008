import { flatMap, orderBy, values } from "lodash-es";
import { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  type Exporter,
  type GetListResult,
  List,
  NumberField,
  type RaRecord,
  TextField,
  TextInput,
  type Translate,
  useTranslate,
} from "react-admin";
import { csvExport } from "../customComponents/PagedExportButtonWithFilter";
import dataProvider from "../dataProvider/dataProvider";
import {
  type Props2String,
  StatsWithExportListActions,
  dateTimeOptionsWithoutSecondsDeCH,
  toDeCHLocaleString,
} from "./Common";
import { MissionInfoStatusDatagrid } from "./MissionInfoStatus";

export const MissionStatusResource = "missionstatus";

export const MissionStatusList = () => {
  const translate = useTranslate();
  const filters = missionStatusFilters(translate);

  return (
    <List
      perPage={25}
      sort={{ field: "startedAt", order: "DESC" }}
      empty={false}
      filters={filters}
      exporter={missionStatusExporter}
      actions={<StatsWithExportListActions filters={filters} />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowStyle={() => ({ height: "2.25rem" })}
        rowClick={"show"}
      >
        <DateField
          source={MissionStatusProps.startedAt}
          showTime
          locales="de-CH"
          options={dateTimeOptionsWithoutSecondsDeCH}
        />
        <NumberField source={MissionStatusProps.rescueVehicleName} />
        <TextField source={MissionStatusProps.title} />
        <TextField source={MissionStatusProps.elsEventId} />
        <TextField source={MissionStatusProps.elsDispatchId} />
      </Datagrid>
    </List>
  );
};

export const MissionStatusShow = () => {
  const match = window.location.hash.match(/\/missionstatus\/(\d+)\/show/);
  const missionId = match ? match[1] : null;
  const [missionInfo, setMissionInfo] = useState<MissionStatus>();

  useEffect(() => {
    if (!missionId) return;
    dataProvider
      .getList("missionstatus", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "startedAt", order: "DESC" },
        filter: {},
      })
      .then(({ data }) => {
        const mission = data.find((m) => m.id === Number.parseInt(missionId));
        setMissionInfo(mission);
      });
  }, [missionId]);

  return (
    <>
      {missionInfo && (
        <h1 style={{ marginBottom: "-2rem" }}>
          {`${missionInfo?.rescueVehicleName}, ${missionInfo?.title}, ${missionInfo?.elsEventId}, ${missionInfo?.elsDispatchId} `}
        </h1>
      )}
      <List
        exporter={false}
        resource="missionstatus"
        filter={{ missionId }}
        empty={false}
      >
        <MissionInfoStatusDatagrid />
      </List>
    </>
  );
};

const missionStatusFilters: (translate: Translate) => JSX.Element[] = (
  translate
) => {
  const field2LabelAndSource = (field: keyof MissionStatusFilterFields) => ({
    label: translate(`da.stats.mission.filter.${field}`),
    source: field,
    style: { minWidth: "10rem", marginLeft: "4px", marginRight: "4px" },
  });

  return [
    <TextInput
      {...field2LabelAndSource(MissionStatusFilterFieldsProps.query)}
      alwaysOn
    />,
  ];
};

type MissionStatusFilterFields = {
  query: string;
};

const missionStatusExporter: Exporter = async (
  listResult: GetListResult<MissionStatus>
) => {
  const allMissionStatsForExport = orderBy(
    flatMap(listResult.data, (missionStats) => {
      const { id, startedAt, finishedAt, acknowledgedAt, ...missionStatsRest } =
        missionStats;

      return flatMap([{}], () => {
        return {
          ...missionStatsRest,
          startedAt: toDeCHLocaleString(startedAt),
          finishedAt: toDeCHLocaleString(finishedAt),
          acknowledgedAt: toDeCHLocaleString(acknowledgedAt),
        };
      });
    }),
    ["id"]
  );

  // Export as CSV
  csvExport(
    allMissionStatsForExport,
    { headers: values(MissionStatsWithInfosExportProps) },
    "mission_status_export"
  );
};
const MissionStatsWithInfosExportProps = {
  rescueVehicleId: "rescueVehicleId",
  rescueVehicleName: "rescueVehicleName",
  startedAt: "startedAt",
  finishedAt: "finishedAt",
  elsDispatchId: "elsDispatchId",
  elsEventId: "elsEventId",
  isAcknowledged: "isAcknowledged",
  acknowledgedAt: "acknowledgedAt",
};

const MissionStatusProps: Props2String<MissionStatus> = {
  id: "id",
  rescueVehicleId: "rescueVehicleId",
  rescueVehicleName: "rescueVehicleName",
  startedAt: "startedAt",
  finishedAt: "finishedAt",
  elsDispatchId: "elsDispatchId",
  elsEventId: "elsEventId",
  isAcknowledged: "isAcknowledged",
  acknowledgedAt: "acknowledgedAt",
  initialNotificationType: "initialNotificationType",
  title: "title",
};

type MissionStatus = RaRecord & {
  id: number;
  rescueVehicleId: number;
  rescueVehicleName: string;
  startedAt: number;
  finishedAt: number;
  elsDispatchId: string;
  elsEventId: string;
  initialNotificationType: MissionNotificationType;
  title: string;
  acknowledgedAt: number;
  isAcknowledged: boolean;
};

export type MissionNotificationType = "INFO" | "ALARM";

export const notificationTypeChoices: {
  id: MissionNotificationType;
  name: MissionNotificationType;
}[] = [
  { id: "INFO", name: "INFO" },
  { id: "ALARM", name: "ALARM" },
];

const MissionStatusFilterFieldsProps: Props2String<MissionStatusFilterFields> =
  {
    query: "query",
  };
