import * as React from "react";
import { FilterButton, TopToolbar, useResourceContext } from "react-admin";
import PagedExportButtonWithFilter from "../customComponents/PagedExportButtonWithFilter";

// magic, see comment of RequiredOnly below
type KnownKeys<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : K]: T[K];
};

// used to extract only the required props from a T, i.e. remove the indexing signature.
export type RequiredOnly<RecordType extends Record<string, any>> = Required<
  KnownKeys<RecordType>
>;

export type Props2String<RecordType extends Record<string, any>> = {
  [K in keyof RequiredOnly<RecordType>]: K;
};

export const viewRedirect = {
  false: false,
  list: "list",
  show: "show",
  create: "create",
  edit: "edit",
};

export const StatsWithExportListActions: React.FC<{
  filters: JSX.Element[];
}> = ({ filters }) => {
  const resource = useResourceContext();
  return (
    <TopToolbar>
      <FilterButton filters={filters} />
      <PagedExportButtonWithFilter
        perPage={1000}
        maxPages={20}
        resource={resource}
      />
    </TopToolbar>
  );
};

export const dateTimeOptionsDeCH: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const dateTimeOptionsWithoutSecondsDeCH: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};

export const toDeCHLocaleString = (timestamp?: number) =>
  timestamp === undefined
    ? undefined
    : new Date(timestamp).toLocaleString("de-CH", dateTimeOptionsDeCH);

export enum ORDER {
  ASC = "ASC",
  DESC = "DESC",
}

export type Coordinate = {
  latitude: number;
  longitude: number;
};
