import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import authProvider, {
  ENTITIES_AUTHORITY,
  LIVE_AUTHORITY,
  OPERATOR_AUTHORITY,
  SETTINGS_AUTHORITY,
  STATS_AUTHORITY,
} from "./authProvider/authProvider";
import { SettingsEdit, SettingsShow } from "./customRoutes/Settings";
import Dashboard from "./dashboard/Dashboard";
import dataProvider from "./dataProvider/dataProvider";
import i18nProvider from "./i18n/i18nProvider";
import { ReactComponent as HelicoptersIcon } from "./images/ic-web-helis.svg";
import { ReactComponent as MissionStatsIcon } from "./images/ic-web-mission-stats.svg";
import { ReactComponent as NotificationStatsIcon } from "./images/ic-web-notification-stats.svg";
import { ReactComponent as ReadinessUserIcon } from "./images/ic-web-readiness-user.svg";
import { ReactComponent as ReadinessVehicleIcon } from "./images/ic-web-readiness-vehicle.svg";
import { ReactComponent as RescueVehiclesIcon } from "./images/ic-web-rescuevehicles.svg";
import { ReactComponent as RolesIcon } from "./images/ic-web-roles.svg";
import { ReactComponent as UsersIcon } from "./images/ic-web-users.svg";
import CustomLayout from "./layout/CustomLayout";
import Login from "./layout/CustomLogin";
import { theme } from "./layout/Theme";
import {
  HelicopterCreate,
  HelicopterEdit,
  HelicopterList,
  HelicopterShow,
  HelicoptersResource,
} from "./resources/Helicopters";
import { MissionInfoStatsResource } from "./resources/MissionInfoStats";
import {
  MissionStatsList,
  MissionStatsResource,
  MissionStatsShow,
} from "./resources/MissionStats";
import {
  MissionStatusList,
  MissionStatusResource,
  MissionStatusShow,
} from "./resources/MissionStatus";
import {
  NotificationStatsList,
  NotificationStatsResource,
} from "./resources/NotificationStats";
import {
  ReadinessByRescueVehicleList,
  ReadinessByRescueVehicleResource,
  ReadinessByRescueVehicleShow,
} from "./resources/ReadinessByRescueVehicle";
import {
  ReadinessByUserList,
  ReadinessByUserResource,
  ReadinessByUserShow,
} from "./resources/ReadinessByUser";
import {
  RescueVehicleEdit,
  RescueVehicleList,
  RescueVehicleShow,
  RescueVehiclesResource,
} from "./resources/RescueVehicles";
import {
  RoleCreate,
  RoleEdit,
  RoleList,
  RoleShow,
  RolesResource,
} from "./resources/Roles";
import {
  ShiftStatsByRescueVehicleResource,
  ShiftStatsByUserResource,
} from "./resources/ShiftStats";
import {
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
  UsersResource,
} from "./resources/Users";

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    theme={theme}
    layout={CustomLayout}
    loginPage={Login}
    dashboard={Dashboard}
  >
    {(permissions) => {
      let resources: React.ReactNode[] = [];
      if (permissions.includes(LIVE_AUTHORITY)) {
        resources = resources.concat([
          <Resource
            name={MissionStatusResource}
            list={MissionStatusList}
            show={MissionStatusShow}
            icon={MissionStatsIcon}
          />,
        ]);
      }
      if (permissions.includes(OPERATOR_AUTHORITY)) {
        resources = resources.concat([
          <Resource
            name={HelicoptersResource}
            list={HelicopterList}
            show={HelicopterShow}
            edit={HelicopterEdit}
            create={HelicopterCreate}
            icon={HelicoptersIcon}
          />,
        ]);
      }

      if (
        permissions.includes(OPERATOR_AUTHORITY) ||
        permissions.includes(ENTITIES_AUTHORITY)
      ) {
        resources = resources.concat([
          <Resource
            name={RescueVehiclesResource}
            list={RescueVehicleList}
            show={RescueVehicleShow}
            edit={RescueVehicleEdit}
            icon={RescueVehiclesIcon}
          />,
        ]);
      }

      if (permissions.includes(ENTITIES_AUTHORITY)) {
        resources = resources.concat([
          <Resource
            name={RolesResource}
            list={RoleList}
            show={RoleShow}
            edit={RoleEdit}
            create={RoleCreate}
            icon={RolesIcon}
          />,
          <Resource
            name={UsersResource}
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
            icon={UsersIcon}
          />,
        ]);
      }
      if (permissions.includes(STATS_AUTHORITY)) {
        resources = resources.concat([
          <Resource
            name={MissionStatsResource}
            list={MissionStatsList}
            show={MissionStatsShow}
            icon={MissionStatsIcon}
          />,
          <Resource
            name={NotificationStatsResource}
            list={NotificationStatsList}
            icon={NotificationStatsIcon}
          />,
          <Resource
            name={ReadinessByUserResource}
            list={ReadinessByUserList}
            show={ReadinessByUserShow}
            icon={ReadinessUserIcon}
          />,
          <Resource
            name={ReadinessByRescueVehicleResource}
            list={ReadinessByRescueVehicleList}
            show={ReadinessByRescueVehicleShow}
            icon={ReadinessVehicleIcon}
          />,
          <Resource name={MissionInfoStatsResource} />,
          <Resource name={ShiftStatsByUserResource} />,
          <Resource name={ShiftStatsByRescueVehicleResource} />,
        ]);
      }

      if (permissions.includes(SETTINGS_AUTHORITY)) {
        resources = resources.concat([
          <CustomRoutes>
            <Route path="/settings" element={<SettingsEdit />} />
            <Route path="/settings/show" element={<SettingsShow />} />
          </CustomRoutes>,
        ]);
      }

      if (resources.length === 0) {
        // must return a resource without list parameter instead of empty array of resources
        return [<Resource name="none" />];
      }

      return resources;
    }}
  </Admin>
);

export default App;
