import { map, orderBy } from "lodash-es";
import devClientSettings from "./dev_appsettings.clients.json";
import intClientSettings from "./int_appsettings.clients.json";
import prodClientSettings from "./prod_appsettings.clients.json";

export const envIsIntOrProd = process.env.REACT_APP_RELEASE_ENV === "PROD" || process.env.REACT_APP_RELEASE_ENV === "INT";

const clientSettings = () => {
    switch (process.env.REACT_APP_RELEASE_ENV) {
        case "PROD":
            return prodClientSettings;
        case "INT":
            return intClientSettings;
        default:
            return devClientSettings;
    }
};

const zeroPad = (num: any, places: number) => String(num).padStart(places, "0");

export const workstationClientIdPairs = map(
    orderBy(
        clientSettings().Configuration.Clients,
        ["NamePrefix", "Id"],
        ["asc", "asc"]
    ),
    ({NamePrefix, Id, HostnamePrefix}) => ({
        workstation: Id === 0 ? NamePrefix : NamePrefix + zeroPad(Id, 2),
        workstationId: HostnamePrefix + zeroPad(Id, 3)
    })
);
